function load(component) {
  return () => import(`@/components/comum/${component}`)
}

function loadC(component) {
  return () => import(`@/components/${component}`)
}

export default [
  {
    path: '/abriranoletivo',
    name: 'Abrir Ano Letivo',
    meta: {
      breadcrumb: [
        {
          text: 'Abrir Ano Letivo',
        },
      ],
    },
    component: loadC('seguranca/MctIniciarAnoLetivo'),
  },

  {
    path: '/relescpedagogico',
    name: 'Relatório Pedagógico',
    meta: {
      breadcrumb: [
        {
          text: 'Relatório Pedadógico',
        },
      ],
    },
    component: loadC('modulos/siges/relatorios/RelEscPedagogico'),
  },

  {
    path: '/escpessoas',
    name: 'Pessoas',
    redirect: '/escpessoas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Pessoas',
        component: loadC('modulos/comum/PessoasLista'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Pessoas',
            },
          ],
        },
        props: {
          source: 'escpessoas/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Pessoa',
        component: loadC('modulos/comum/Pessoas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Pessoas',
              to: '/escpessoas',
            },
            {
              text: 'Nova Pessoa',
            },
          ],
        },
        props: {
          source: 'escpessoas/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Pessoa',
        component: loadC('modulos/comum/Pessoas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Pessoas',
              to: '/escpessoas',
            },
            {
              text: 'Editar Pessoa',
            },
          ],
        },
        props: {
          source: 'escpessoas/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Pessoa',
        component: loadC('modulos/comum/Pessoas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Pessoas',
              to: '/escpessoas',
            },
            {
              text: 'Deletar Pessoa',
            },
          ],
        },
        props: {
          source: 'escpessoas/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Pessoa',
        component: loadC('modulos/comum/Pessoas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Pessoas',
              to: '/escpessoas',
            },
            {
              text: 'Visualizar Pessoa',
            },
          ],
        },
        props: {
          source: 'escpessoas/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escareasconhecimento',
    name: 'Áreas de Conhecimento',
    redirect: '/escareasconhecimento/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Áreas de Conhecimento',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Áreas de Conhecimento',
            },
          ],
        },
        props: {
          source: 'escareasconhecimento/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Áreas de Conhecimento',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Áreas de Conhecimento',
              to: '/escareasconhecimento',
            },
            {
              text: 'Nova Áreas de Conhecimento',
            },
          ],
        },
        props: {
          source: 'escareasconhecimento/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Áreas de Conhecimento',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Áreas de Conhecimento',
              to: '/escareasconhecimento',
            },
            {
              text: 'Editar Áreas de Conhecimento',
            },
          ],
        },
        props: {
          source: 'escareasconhecimento/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Áreas de Conhecimento',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Áreas de Conhecimento',
              to: '/escareasconhecimento',
            },
            {
              text: 'Deletar Áreas de Conhecimento',
            },
          ],
        },
        props: {
          source: 'escareasconhecimento/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Áreas de Conhecimento',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Áreas de Conhecimento',
              to: '/escareasconhecimento',
            },
            {
              text: 'Visualizar Áreas de Conhecimento',
            },
          ],
        },
        props: {
          source: 'escareasconhecimento/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escdisciplina',
    name: 'Disciplinas',
    redirect: '/escdisciplina/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Disciplinas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Disciplinas',
            },
          ],
        },
        props: {
          source: 'escdisciplina/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Disciplina',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Disciplinas',
              to: '/escdisciplina',
            },
            {
              text: 'Nova Disciplina',
            },
          ],
        },
        props: {
          source: 'escdisciplina/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Disciplina',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Disciplinas',
              to: '/escdisciplina',
            },
            {
              text: 'Editar Disciplina',
            },
          ],
        },
        props: {
          source: 'escdisciplina/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Disciplina',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Disciplinas',
              to: '/escdisciplina',
            },
            {
              text: 'Deletar Disciplina',
            },
          ],
        },
        props: {
          source: 'escdisciplina/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Disciplina',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Disciplinas',
              to: '/escdisciplina',
            },
            {
              text: 'Visualizar Disciplina',
            },
          ],
        },
        props: {
          source: 'escdisciplina/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escconceito',
    name: 'Conceito',
    redirect: '/escconceito/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Conceito',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Conceito',
            },
          ],
        },
        props: {
          source: 'escconceito/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Conceito',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Conceito',
              to: '/escconceito',
            },
            {
              text: 'Novo Conceito',
            },
          ],
        },
        props: {
          source: 'escconceito/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Conceito',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Conceito',
              to: '/escconceito',
            },
            {
              text: 'Editar Conceito',
            },
          ],
        },
        props: {
          source: 'escconceito/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Conceito',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Conceito',
              to: '/escconceito',
            },
            {
              text: 'Deletar Conceito',
            },
          ],
        },
        props: {
          source: 'escconceito/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Conceito',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Conceito',
              to: '/escconceito',
            },
            {
              text: 'Visualizar Conceito',
            },
          ],
        },
        props: {
          source: 'escconceito/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/esctpsalas',
    name: 'Tipos de Salas',
    redirect: '/esctpsalas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Tipos de Salas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Sala',
            },
          ],
        },
        props: {
          source: 'esctpsalas/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Tipo de Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Sala',
              to: '/esctpsalas',
            },
            {
              text: 'Novo Tipo de Sala',
            },
          ],
        },
        props: {
          source: 'esctpsalas/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Tipo de Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Sala',
              to: '/esctpsalas',
            },
            {
              text: 'Editar Tipo de Sala',
            },
          ],
        },
        props: {
          source: 'esctpsalas/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Tipo de Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Sala',
              to: '/esctpsalas',
            },
            {
              text: 'Deletar Tipo de Sala',
            },
          ],
        },
        props: {
          source: 'esctpsalas/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Tipo de Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Sala',
              to: '/esctpsalas',
            },
            {
              text: 'Visualizar Tipo de Sala',
            },
          ],
        },
        props: {
          source: 'esctpsalas/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escunidades',
    name: 'Unidades Escolar',
    redirect: '/escunidades/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Unidades Escolares/Polos',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Unidades Escolares/Polos',
            },
          ],
        },
        props: {
          source: 'escunidades/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Unidade Escolar/Polo',
        component: loadC('modulos/siges/EscUnidades'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Unidades Escolares/Polos',
              to: '/escunidades',
            },
            {
              text: 'Novo Unidade Escolar/Polo',
            },
          ],
        },
        props: {
          source: 'escunidades/novo',
          modo: 'novo',
        },
      },
      {
        path: ':uni_codigo/:uni_ent/:uni_esc/editar',
        name: 'Editar Unidade Escolar/Polo',
        component: loadC('modulos/siges/EscUnidades'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Unidades Escolares/Polos',
              to: '/escunidades',
            },
            {
              text: 'Editar Unidade Escolar/Polo',
            },
          ],
        },
        props: {
          source: 'escunidades/editar',
          modo: 'editar',
        },
      },
      {
        path: ':uni_codigo/:uni_ent/:uni_esc/deletar',
        name: 'Deletar Unidade Escolar/Polo',
        component: loadC('modulos/siges/EscUnidades'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Unidades Escolares/Polos',
              to: '/escunidades',
            },
            {
              text: 'Deletar Unidade Escolar/Polo',
            },
          ],
        },
        props: {
          source: 'escunidades/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':uni_codigo/:uni_ent/:uni_esc/visualizar',
        name: 'Visualizar Unidade Escolar/Polo',
        component: loadC('modulos/siges/EscUnidades'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Unidades Escolares/Polos',
              to: '/escunidades',
            },
            {
              text: 'Visualizar Unidade Escolar/Polo',
            },
          ],
        },
        props: {
          source: 'escunidades/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escsalas',
    name: 'Dependências/ Salas',
    redirect: '/escsalas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Salas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Salas',
            },
          ],
        },
        props: {
          source: 'escsalas/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Salas',
              to: '/escsalas',
            },
            {
              text: 'Nova Sala',
            },
          ],
        },
        props: {
          source: 'escsalas/novo',
          modo: 'novo',
        },
      },
      {
        path: ':sal_codigo/:sal_ent/:sal_esc/editar',
        name: 'Editar Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Salas',
              to: '/escsalas',
            },
            {
              text: 'Editar Sala',
            },
          ],
        },
        props: {
          source: 'escsalas/editar',
          modo: 'editar',
        },
      },
      {
        path: ':sal_codigo/:sal_ent/:sal_esc/deletar',
        name: 'Deletar Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Salas',
              to: '/escsalas',
            },
            {
              text: 'Deletar Sala',
            },
          ],
        },
        props: {
          source: 'escsalas/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':sal_codigo/:sal_ent/:sal_esc/visualizar',
        name: 'Visualizar Sala',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Salas',
              to: '/escsalas',
            },
            {
              text: 'Visualizar Sala',
            },
          ],
        },
        props: {
          source: 'escsalas/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escturno',
    name: 'Turnos',
    redirect: '/escturno/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Turnos',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turnos',
            },
          ],
        },
        props: {
          source: 'escturno/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Turno',
        component: loadC('modulos/siges/EscTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turnos',
              to: '/escturno',
            },
            {
              text: 'Novo Turno',
            },
          ],
        },
        props: {
          source: 'escturno/novo',
          modo: 'novo',
        },
      },
      {
        path: ':turn_codigo/:turn_ano/:turn_ent/:turn_esc/editar',
        name: 'Editar Turno',
        component: loadC('modulos/siges/EscTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turnos',
              to: '/escturno',
            },
            {
              text: 'Editar Turno',
            },
          ],
        },
        props: {
          source: 'escturno/editar',
          modo: 'editar',
        },
      },
      {
        path: ':turn_codigo/:turn_ano/:turn_ent/:turn_esc/deletar',
        name: 'Deletar Turno',
        component: loadC('modulos/siges/EscTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turnos',
              to: '/escturno',
            },
            {
              text: 'Deletar Turno',
            },
          ],
        },
        props: {
          source: 'escturno/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':turn_codigo/:turn_ano/:turn_ent/:turn_esc/visualizar',
        name: 'Visualizar Turno',
        component: loadC('modulos/siges/EscTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turnos',
              to: '/escturno',
            },
            {
              text: 'Visualizar Turno',
            },
          ],
        },
        props: {
          source: 'escturno/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/esccursos',
    name: 'Cursos',
    redirect: '/esccursos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Cursos',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Cursos',
            },
          ],
        },
        props: {
          source: 'esccursos/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Curso',
        component: loadC('modulos/siges/EscCursos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Cursos',
              to: '/esccursos',
            },
            {
              text: 'Novo Curso',
            },
          ],
        },
        props: {
          source: 'esccursos/novo',
          modo: 'novo',
        },
      },
      {
        path: ':cur_codigo/:cur_ano/:cur_ent/:cur_esc/editar',
        name: 'Editar Curso',
        component: loadC('modulos/siges/EscCursos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Cursos',
              to: '/esccursos',
            },
            {
              text: 'Editar Curso',
            },
          ],
        },
        props: {
          source: 'esccursos/editar',
          modo: 'editar',
        },
      },
      {
        path: ':cur_codigo/:cur_ano/:cur_ent/:cur_esc/deletar',
        name: 'Deletar Curso',
        component: loadC('modulos/siges/EscCursos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Cursos',
              to: '/esccursos',
            },
            {
              text: 'Deletar Curso',
            },
          ],
        },
        props: {
          source: 'esccursos/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':cur_codigo/:cur_ano/:cur_ent/:cur_esc/visualizar',
        name: 'Visualizar Curso',
        component: loadC('modulos/siges/EscCursos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Cursos',
              to: '/esccursos',
            },
            {
              text: 'Visualizar Curso',
            },
          ],
        },
        props: {
          source: 'esccursos/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escescola',
    name: 'Escolas',
    redirect: '/escescola/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Escolas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Escolas',
            },
          ],
        },
        props: {
          source: 'escescola/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Escola',
        component: loadC('modulos/siges/EscEscolas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Escolas',
              to: '/escescola',
            },
            {
              text: 'Nova Escola',
            },
          ],
        },
        props: {
          source: 'escescola/novo',
          modo: 'novo',
        },
      },
      {
        path: ':esc_codigo/:esc_ent/editar',
        name: 'Editar Escola',
        component: loadC('modulos/siges/EscEscolas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Escolas',
              to: '/escescola',
            },
            {
              text: 'Editar Escola',
            },
          ],
        },
        props: {
          source: 'escescola/editar',
          modo: 'editar',
        },
      },
      {
        path: ':esc_codigo/:esc_ent/deletar',
        name: 'Deletar Escola',
        component: loadC('modulos/siges/EscEscolas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Escolas',
              to: '/escescola',
            },
            {
              text: 'Deletar Escola',
            },
          ],
        },
        props: {
          source: 'escescola/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':esc_codigo/:esc_ent/visualizar',
        name: 'Visualizar Escola',
        component: loadC('modulos/siges/EscEscolas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Escolas',
              to: '/escescola',
            },
            {
              text: 'Visualizar Escola',
            },
          ],
        },
        props: {
          source: 'escescola/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escserie',
    name: 'Ano / Série',
    redirect: '/escserie/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Ano',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Ano',
            },
          ],
        },
        props: {
          source: 'escserie/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Ano',
        component: loadC('modulos/siges/EscSeries'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Ano',
              to: '/escserie',
            },
            {
              text: 'Novo Ano',
            },
          ],
        },
        props: {
          source: 'escserie/novo',
          modo: 'novo',
        },
      },
      {
        path: ':ser_codigo/:ser_ent/:ser_ano/:ser_esc/editar',
        name: 'Editar Ano',
        component: loadC('modulos/siges/EscSeries'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Ano',
              to: '/escserie',
            },
            {
              text: 'Editar Ano',
            },
          ],
        },
        props: {
          source: 'escserie/editar',
          modo: 'editar',
        },
      },
      {
        path: ':ser_codigo/:ser_ent/:ser_ano/:ser_esc/deletar',
        name: 'Deletar Ano',
        component: loadC('modulos/siges/EscSeries'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Ano',
              to: '/escserie',
            },
            {
              text: 'Deletar Ano',
            },
          ],
        },
        props: {
          source: 'escserie/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':ser_codigo/:ser_ent/:ser_ano/:ser_esc/visualizar',
        name: 'Visualizar Ano',
        component: loadC('modulos/siges/EscSeries'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Ano',
              to: '/escserie',
            },
            {
              text: 'Visualizar Ano',
            },
          ],
        },
        props: {
          source: 'escserie/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/esccriterios',
    name: 'Critérios',
    redirect: '/esccriterios/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Critérios',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Critérios',
            },
          ],
        },
        props: {
          source: 'esccriterios/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Critério',
        component: loadC('modulos/siges/EscCriterios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Critérios',
              to: '/esccriterios',
            },
            {
              text: 'Novo Critério',
            },
          ],
        },
        props: {
          source: 'esccriterios/novo',
          modo: 'novo',
        },
      },
      {
        path: ':crit_codigo/:crit_ano/:crit_ent/:crit_esc/editar',
        name: 'Editar Critério',
        component: loadC('modulos/siges/EscCriterios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Critérios',
              to: '/esccriterios',
            },
            {
              text: 'Editar Critério',
            },
          ],
        },
        props: {
          source: 'esccriterios/editar',
          modo: 'editar',
        },
      },
      {
        path: ':crit_codigo/:crit_ano/:crit_ent/:crit_esc/deletar',
        name: 'Deletar Critério',
        component: loadC('modulos/siges/EscCriterios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Critérios',
              to: '/esccriterios',
            },
            {
              text: 'Deletar Critério',
            },
          ],
        },
        props: {
          source: 'esccriterios/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':crit_codigo/:crit_ano/:crit_ent/:crit_esc/visualizar',
        name: 'Visualizar Critério',
        component: loadC('modulos/siges/EscCriterios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Critérios',
              to: '/esccriterios',
            },
            {
              text: 'Visualizar Critério',
            },
          ],
        },
        props: {
          source: 'esccriterios/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escturmas',
    name: 'Turmas',
    redirect: '/escturmas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Turmas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turmas',
            },
          ],
        },
        props: {
          source: 'escturmas/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Turma',
        component: loadC('modulos/siges/EscTurmas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turmas',
              to: '/escturmas',
            },
            {
              text: 'Nova Turma',
            },
          ],
        },
        props: {
          source: 'escturmas/novo',
          modo: 'novo',
        },
      },
      {
        path: ':turm_codigo/:turm_ent/:turm_ano/:turm_esc/editar',
        name: 'Editar Turma',
        component: loadC('modulos/siges/EscTurmas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turmas',
              to: '/escturmas',
            },
            {
              text: 'Editar Turma',
            },
          ],
        },
        props: {
          source: 'escturmas/editar',
          modo: 'editar',
        },
      },
      {
        path: ':turm_codigo/:turm_ent/:turm_ano/:turm_esc/deletar',
        name: 'Deletar Turma',
        component: loadC('modulos/siges/EscTurmas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turmas',
              to: '/escturmas',
            },
            {
              text: 'Deletar Turma',
            },
          ],
        },
        props: {
          source: 'escturmas/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':turm_codigo/:turm_ent/:turm_ano/:turm_esc/visualizar',
        name: 'Visualizar Turma',
        component: loadC('modulos/siges/EscTurmas'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Turmas',
              to: '/escturmas',
            },
            {
              text: 'Visualizar Turma',
            },
          ],
        },
        props: {
          source: 'escturmas/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escagendamento',
    name: 'Agendamento de Turmas',
    redirect: '/escagendamento/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Agendamento de Turmas',
        component: loadC('modulos/siges/EscAgendamentosLista'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
            },
          ],
        },
        props: {
          source: 'escagendamento/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Agendamento de Turma',
        component: loadC('modulos/siges/EscAgendamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamento',
            },
            {
              text: 'Novo Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamento/novo',
          modo: 'novo',
        },
      },
      {
        path: ':horari_codigo/:cur_codigo/:turm_codigo/:ser_codigo/editar',
        name: 'Editar Agendamento de Turma',
        component: loadC('modulos/siges/EscAgendamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamento',
            },
            {
              text: 'Editar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamento/editar',
          modo: 'editar',
        },
      },
      {
        path: ':horari_codigo/:cur_codigo/:turm_codigo/:ser_codigo/deletar',
        name: 'Deletar Agendamento de Turma',
        component: loadC('modulos/siges/EscAgendamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamento',
            },
            {
              text: 'Deletar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamento/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':horari_codigo/:cur_codigo/:turm_codigo/:ser_codigo/visualizar',
        name: 'Visualizar Agendamento de Turma',
        component: loadC('modulos/siges/EscAgendamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamento',
            },
            {
              text: 'Visualizar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamento/editar',
          modo: 'visualizar',
        },
      },
      {
        path: 'agendteste1',
        name: 'Agendamento',
        component: loadC('modulos/siges/EscAgendamentosT'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamento',
            },
            {
              text: 'Visualizar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamentot/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escagendamentot',
    name: 'Agendamentos',
    redirect: '/escagendamentot/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Agendamentos',
        component: loadC('modulos/siges/EscAgendamentosListaT'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
            },
          ],
        },
        props: {
          source: 'escagendamentot/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Agendamento',
        component: loadC('modulos/siges/EscAgendamentosT'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamentot',
            },
            {
              text: 'Novo Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamentot/novo',
          modo: 'novo',
        },
      },
      {
        path: ':turm_codigo/:turm_ent/:turm_ano/:turm_esc/editar',
        name: 'Editar Agendamento',
        component: loadC('modulos/siges/EscAgendamentosT'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamentot',
            },
            {
              text: 'Editar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamentot/editar',
          modo: 'editar',
        },
      },
      {
        path: ':turm_codigo/:turm_ent/:turm_ano/:turm_esc/deletar',
        name: 'Deletar Agendamento',
        component: loadC('modulos/siges/EscAgendamentosT'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamentot',
            },
            {
              text: 'Deletar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamentot/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':turm_codigo/:turm_ent/:turm_ano/:turm_esc/visualizar',
        name: 'Visualizar Agendamento',
        component: loadC('modulos/siges/EscAgendamentosT'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Agendamento de Turmas',
              to: '/escagendamentot',
            },
            {
              text: 'Visualizar Agendamento de Turma',
            },
          ],
        },
        props: {
          source: 'escagendamentot/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/esctpcalendario',
    name: 'Tipos de dias do Calendário',
    redirect: '/esctpcalendario/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Tipos de Dias do Calendário',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Dias do Calendário',
            },
          ],
        },
        props: {
          source: 'esctpcalendario/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Tipo de dias do Calendário',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Dias do Calendário',
              to: '/esctpcalendario',
            },
            {
              text: 'Novo Tipo de Dia do Calendário',
            },
          ],
        },
        props: {
          source: 'esctpcalendario/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Tipo de dias do Calendário',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Dias do Calendário',
              to: '/esctpcalendario',
            },
            {
              text: 'Editar Tipo de Dia do Calendário',
            },
          ],
        },
        props: {
          source: 'esctpcalendario/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Tipo de dias do Calendário',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Dias do Calendário',
              to: '/esctpcalendario',
            },
            {
              text: 'Deletar Tipo de Dia do Calendário',
            },
          ],
        },
        props: {
          source: 'esctpcalendario/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Tipo de dias do Calendário',
        component: load('MctFormViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Tipos de Dias do Calendário',
              to: '/esctpcalendario',
            },
            {
              text: 'Visualizar Tipo de Dia do Calendário',
            },
          ],
        },
        props: {
          source: 'esctpcalendario/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/esccalendario',
    name: 'Calendário',
    redirect: '/esccalendario/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Calendário',
        component: loadC('modulos/siges/EscCalendarioLista'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Calendário',
            },
          ],
        },
        props: {
          source: 'esccalendario/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Calendário',
        component: loadC('modulos/siges/EscCalendario'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Calendário',
              to: '/esccalendario',
            },
            {
              text: 'Novo Calendário',
            },
          ],
        },
        props: {
          source: 'esccalendario/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Calendário',
        component: loadC('modulos/siges/EscCalendario'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Calendário',
              to: '/esccalendario',
            },
            {
              text: 'Editar Calendário',
            },
          ],
        },
        props: {
          source: 'esccalendario/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Calendário',
        component: loadC('modulos/siges/EscCalendario'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Calendário',
              to: '/esccalendario',
            },
            {
              text: 'Deletar Calendário',
            },
          ],
        },
        props: {
          source: 'esccalendario/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Calendário',
        component: loadC('modulos/siges/EscCalendario'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Calendário',
              to: '/esccalendario',
            },
            {
              text: 'Visualizar Calendário',
            },
          ],
        },
        props: {
          source: 'esccalendario/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/esccurriculo',
    name: 'Matriz Curricular',
    redirect: '/esccurriculo/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Matriz Curricular',
        component: loadC('modulos/siges/EscMatrizCurricularLista'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matriz Curricular',
            },
          ],
        },
        props: {
          source: 'esccurriculo/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Matriz Curricular',
        component: loadC('modulos/siges/EscMatrizCurricular'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matriz Curricular',
              to: '/esccurriculo',
            },
            {
              text: 'Nova Matriz Curricular',
            },
          ],
        },
        props: {
          source: 'esccurriculo/novo',
          modo: 'novo',
        },
      },
      {
        path: ':curr_codigo/:curr_ent/:curr_esc/:curr_ano/editar',
        name: 'Editar Matriz Curricular',
        component: loadC('modulos/siges/EscMatrizCurricular'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matriz Curricular',
              to: '/esccurriculo',
            },
            {
              text: 'Editar Matriz Curricular',
            },
          ],
        },
        props: {
          source: 'esccurriculo/editar',
          modo: 'editar',
        },
      },
      {
        path: ':curr_codigo/:curr_ent/:curr_esc/:curr_ano/deletar',
        name: 'Deletar Matriz Curricular',
        component: loadC('modulos/siges/EscMatrizCurricular'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matriz Curricular',
              to: '/esccurriculo',
            },
            {
              text: 'Deletar Matriz Curricular',
            },
          ],
        },
        props: {
          source: 'esccurriculo/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':curr_codigo/:curr_ent/:curr_esc/:curr_ano/visualizar',
        name: 'Visualizar Matriz Curricular',
        component: loadC('modulos/siges/EscMatrizCurricular'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matriz Curricular',
              to: '/esccurriculo',
            },
            {
              text: 'Visualizar Matriz Curricular',
            },
          ],
        },
        props: {
          source: 'esccurriculo/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/eschorarios',
    name: 'Horários',
    redirect: '/eschorarios/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Horários',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários',
            },
          ],
        },
        props: {
          source: 'eschorarios/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Horário',
        component: loadC('modulos/siges/EscHorarios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários',
              to: '/eschorarios',
            },
            {
              text: 'Novo Horário',
            },
          ],
        },
        props: {
          source: 'eschorarios/novo',
          modo: 'novo',
        },
      },
      {
        path: ':horari_codigo/:horari_ent/:horari_esc/:horari_ano/editar',
        name: 'Editar Horário',
        component: loadC('modulos/siges/EscHorarios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários',
              to: '/eschorarios',
            },
            {
              text: 'Editar Horário',
            },
          ],
        },
        props: {
          source: 'eschorarios/editar',
          modo: 'editar',
        },
      },
      {
        path: ':horari_codigo/:horari_ent/:horari_esc/:horari_ano/deletar',
        name: 'Deletar Horário',
        component: loadC('modulos/siges/EscHorarios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários',
              to: '/eschorarios',
            },
            {
              text: 'Deletar Horário',
            },
          ],
        },
        props: {
          source: 'eschorarios/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':horari_codigo/:horari_ent/:horari_esc/:horari_ano/visualizar',
        name: 'Visualizar Horário',
        component: loadC('modulos/siges/EscHorarios'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários',
              to: '/eschorarios',
            },
            {
              text: 'Visualizar Horário',
            },
          ],
        },
        props: {
          source: 'eschorarios/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/eschorariosdia',
    name: 'Horários - Por Dia',
    redirect: '/eschorariosdia/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Horários - Por Dia',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários - Por Dia',
            },
          ],
        },
        props: {
          source: 'eschorariosdia/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Horário - Por Dia',
        component: loadC('modulos/siges/EscHorariosDia'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários - Por Dia',
              to: '/eschorariosdia',
            },
            {
              text: 'Novo Horário - Por Dia',
            },
          ],
        },
        props: {
          source: 'eschorariosdia/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Horário - Por Dia',
        component: loadC('modulos/siges/EscHorariosDia'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários - Por Dia',
              to: '/eschorariosdia',
            },
            {
              text: 'Editar Horário - Por Dia',
            },
          ],
        },
        props: {
          source: 'eschorariosdia/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Horário - Por Dia',
        component: loadC('modulos/siges/EscHorariosDia'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários - Por Dia',
              to: '/eschorariosdia',
            },
            {
              text: 'Deletar Horário - Por Dia',
            },
          ],
        },
        props: {
          source: 'eschorariosdia/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Horário - Por Dia',
        component: loadC('modulos/siges/EscHorariosDia'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Horários - Por Dia',
              to: '/eschorariosdia',
            },
            {
              text: 'Visualizar Horário - Por Dia',
            },
          ],
        },
        props: {
          source: 'eschorariosdia/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escmatriculascurso',
    name: 'Matricula',
    redirect: '/escmatriculascurso/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova Matrícula',
        component: loadC('modulos/siges/EscMatriculaCurso'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova Matrícula',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escmatriculastransferencia',
    name: 'Transferência de Matricula',
    redirect: '/escmatriculastransferencia/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova Transferência de Matricula',
        component: loadC('modulos/siges/EscMatriculaTransferencia'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova Transferência de Matrícula',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escmatriculas',
    name: 'Matrícula Individual',
    redirect: '/escmatriculas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Matriculas',
        component: loadC('modulos/siges/EscMatriculaLista'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Matricula Individual',
        component: loadC('modulos/siges/EscMatriculaIndividual'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas',
              to: '/escmatriculas',
            },
            {
              text: 'Nova Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/novo',
          modo: 'novo',
        },
      },
      {
        path: ':matri_codigo/:matri_ent/:matri_esc/:matri_ano/editar',
        name: 'Editar Matricula do Aluno',
        component: loadC('modulos/siges/EscMatricula'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas',
              to: '/escmatriculas',
            },
            {
              text: 'Editar Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/editar',
          modo: 'visualizar',
        },
      },
      {
        path: ':matri_codigo/:matri_ent/:matri_esc/:matri_ano/deletar',
        name: 'Deletar Matricula do Aluno',
        component: loadC('modulos/siges/EscMatricula'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas',
              to: '/escmatriculas',
            },
            {
              text: 'Deletar Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':matri_codigo/:matri_ent/:matri_esc/:matri_ano/visualizar',
        name: 'Visualizar Matricula do Aluno',
        component: loadC('modulos/siges/EscMatricula'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas',
              to: '/escmatriculas',
            },
            {
              text: 'Visualizar Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escmatriculas2',
    name: 'Matricula Individual',
    redirect: '/escmatriculas2/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Matriculas Individual',
        component: loadC('modulos/siges/EscMatriculaLista2'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/lista2',
        },
      },
      {
        path: 'novo',
        name: 'Nova Matricula Individual 2',
        component: loadC('modulos/siges/EscMatriculaIndividual'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas Individual',
              to: '/escmatriculas2',
            },
            {
              text: 'Nova Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/novo',
          modo: 'novo',
        },
      },
      {
        path: ':matri_codigo/:matri_ent/:matri_esc/:matri_ano/editar',
        name: 'Editar Matricula do Aluno 2',
        component: loadC('modulos/siges/EscMatricula'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas Individual',
              to: '/escmatriculas2',
            },
            {
              text: 'Editar Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/editar',
          modo: 'visualizar',
        },
      },
      {
        path: ':matri_codigo/:matri_ent/:matri_esc/:matri_ano/deletar',
        name: 'Deletar Matricula do Aluno 2',
        component: loadC('modulos/siges/EscMatricula'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas Individual',
              to: '/escmatriculas2',
            },
            {
              text: 'Deletar Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':matri_codigo/:matri_ent/:matri_esc/:matri_ano/visualizar',
        name: 'Visualizar Matricula do Aluno 2',
        component: loadC('modulos/siges/EscMatricula'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Matrículas Individual',
              to: '/escmatriculas2',
            },
            {
              text: 'Visualizar Matrícula Individual',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escmatriculacoletiva',
    name: 'Matricula Coletiva',
    redirect: '/escmatriculas/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova Matricula Coletiva',
        component: loadC('modulos/siges/EscMatriculaColetiva'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova Matrícula Coletiva',
            },
          ],
        },
        props: {
          source: 'escmatriculaaluno/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escalunosfrequencia',
    name: 'Frequência do Aluno',
    redirect: '/escalunosfrequencia/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Frequência do Aluno',
        component: loadC('modulos/siges/EscFrequenciaAluno'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Frequência do Aluno',
            },
          ],
        },
        props: {
          source: 'escalunosfrequencia/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escfrequenciaprofessor',
    name: 'Professor - Lançamento de Frequência do Aluno',
    redirect: '/escfrequenciaprofessor/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Professor - Lançamento de Frequência do Aluno',
        component: loadC('modulos/siges/EscFrequenciaProfessor'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Professor - Lançamento de Frequência do Aluno',
            },
          ],
        },
        props: {
          source: 'escalunosfrequencia/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escalunosfrequenciadata',
    name: 'Frequência do Aluno (Por Intervalo de Data)',
    redirect: '/escalunosfrequenciadata/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Frequência do Aluno (Por Intervalo de Data)',
        component: loadC('modulos/siges/EscFrequenciaAlunoData'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Frequência do Aluno (Por Intervalo de Data)',
            },
          ],
        },
        props: {
          source: 'escalunosfrequencia/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escfrequenciaprofessordata',
    name: 'Professor - Frequência do Aluno',
    redirect: '/escfrequenciaprofessordata/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Professor - Frequência do Aluno (Por Intervalo de Data)',
        component: loadC('modulos/siges/EscFrequenciaProfessorData'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Professor - Frequência do Aluno (Por Intervalo de Data)',
            },
          ],
        },
        props: {
          source: 'escalunosfrequencia/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escalunosacompanhamento',
    name: 'Rel. de Acompanhamento do Aluno',
    redirect: '/escalunosacompanhamento/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Rel. de Acompanhamento do Aluno',
        component: loadC('modulos/siges/EscAcompanhamentoAluno'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Rel. de Acompanhamento do Aluno',
            },
          ],
        },
        props: {
          source: 'escalunosacompanhamento/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escalunosacompanhamentoprofesssor',
    name: 'Rel. de Acompanhamento do Aluno pelo Professor',
    redirect: '/escalunosacompanhamentoprofesssor/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Rel. de Acompanhamento do Aluno pelo Professor',
        component: loadC('modulos/siges/EscAcompanhamentoAlunoProfessor'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Rel. de Acompanhamento do Aluno pelo Professor',
            },
          ],
        },
        props: {
          source: 'escalunosacompanhamentoprofesssor/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escconteudo',
    name: 'Conteúdo das Aulas',
    redirect: '/escconteudo/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Novo/Edita Conteúdo das Aulas',
        component: loadC('modulos/siges/EscConteudoAulas'),
        meta: {
          breadcrumb: [
            {
              text: 'Novo/Edita Conteúdo das Aulas',
            },
          ],
        },
        props: {
          source: 'escconteudo/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escconteudoprofessor',
    name: 'Professor - Conteúdo das Aulas',
    redirect: '/escconteudoprofessor/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Novo/Edita Professor - Conteúdo das Aulas',
        component: loadC('modulos/siges/EscConteudoProfessor'),
        meta: {
          breadcrumb: [
            {
              text: 'Novo/Edita Professor - Conteúdo das Aulas',
            },
          ],
        },
        props: {
          source: 'escconteudo/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escconteudoprofessordata',
    name: 'Professor - Conteúdo das Aulas (Por Intervalo de Data)',
    redirect: '/escconteudoprofessordata/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Novo/Edita Professor - Conteúdo das Aulas (Por Intervalo de Data)',
        component: loadC('modulos/siges/EscConteudoProfessorData'),
        meta: {
          breadcrumb: [
            {
              text: 'Novo/Edita Professor - Conteúdo das Aulas (Por Intervalo de Data)',
            },
          ],
        },
        props: {
          source: 'escconteudo/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/esclancamentosprofessorlista',
    name: 'Professor - Lançamentos (Por Intervalo de Data)',
    redirect: '/esclancamentosprofessorlista/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Professor - Lançamentos (Por Intervalo de Data) - Lista',
        component: loadC('modulos/siges/EscLancamentosProfessorLista'),
        meta: {
          breadcrumb: [
            {
              text: 'Professor - Lançamentos (Por Intervalo de Data)',
            },
          ],
        },
        props: {
          source: 'escconteudo/lista',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escprofessorturnos',
    name: 'Config. de Professores por Turno',
    redirect: '/escprofessorturnos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Config. de Professores por Turno',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. de Professores por Turno',
            },
          ],
        },
        props: {
          source: 'escprofessorturnos/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Config. de Professor por Turno',
        component: loadC('modulos/siges/EscProfessorTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. de Professores por Turno',
              to: '/escprofessorturnos',
            },
            {
              text: 'Nova Config. de Professores por Turno',
            },
          ],
        },
        props: {
          source: 'escprofessorturnos/novo',
          modo: 'novo',
        },
      },
      {
        path: ':profturn_codigo/:profturn_ent/:profturn_esc/:profturn_ano/editar',
        name: 'Editar Config. de Professor por Turno',
        component: loadC('modulos/siges/EscProfessorTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. de Professores por Turno',
              to: '/escprofessorturnos',
            },
            {
              text: 'Editar Config. de Professores por Turno',
            },
          ],
        },
        props: {
          source: 'escprofessorturnos/editar',
          modo: 'editar',
        },
      },
      {
        path: ':profturn_codigo/:profturn_ent/:profturn_esc/:profturn_ano/deletar',
        name: 'Deletar Config. de Professor por Turno',
        component: loadC('modulos/siges/EscProfessorTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. de Professores por Turno',
              to: '/escprofessorturnos',
            },
            {
              text: 'Deletar Config. de Professores por Turno',
            },
          ],
        },
        props: {
          source: 'escprofessorturnos/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':profturn_codigo/:profturn_ent/:profturn_esc/:profturn_ano/visualizar',
        name: 'Visualizar Config. de Professor por Turno',
        component: loadC('modulos/siges/EscProfessorTurnos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. de Professores por Turno',
              to: '/escprofessorturnos',
            },
            {
              text: 'Visualizar Config. de Professores por Turno',
            },
          ],
        },
        props: {
          source: 'escprofessorturnos/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escprofessorturma',
    name: 'Config. Professores Turmas',
    redirect: '/escprofessorturma/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Config. Professores Turmas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. Professores Turmas',
            },
          ],
        },
        props: {
          source: 'escprofessorturma/lista',
        },
      },
      {
        path: 'novo',
        name: 'Nova Config. Professor Turma',
        component: loadC('modulos/siges/EscProfessorTurma'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. Professores Turmas',
              to: '/escprofessorturma',
            },
            {
              text: 'Nova Config. Professor Turma',
            },
          ],
        },
        props: {
          source: 'escprofessorturma/novo',
          modo: 'novo',
        },
      },
      {
        path: ':profturm_codigo/:profturm_ent/:profturm_esc/:profturm_ano/editar',
        name: 'Editar Config. Professor Turma',
        component: loadC('modulos/siges/EscProfessorTurma'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. Professores Turmas',
              to: '/escprofessorturma',
            },
            {
              text: 'Editar Config. Professor Turma',
            },
          ],
        },
        props: {
          source: 'escprofessorturma/editar',
          modo: 'editar',
        },
      },
      {
        path: ':profturm_codigo/:profturm_ent/:profturm_esc/:profturm_ano/deletar',
        name: 'Deletar Config. Professor Turma',
        component: loadC('modulos/siges/EscProfessorTurma'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. Professores Turmas',
              to: '/escprofessorturma',
            },
            {
              text: 'Deletar Config. Professor Turma',
            },
          ],
        },
        props: {
          source: 'escprofessorturma/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':profturm_codigo/:profturm_ent/:profturm_esc/:profturm_ano/visualizar',
        name: 'Visualizar Config. Professor Turma',
        component: loadC('modulos/siges/EscProfessorTurma'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Config. Professores Turmas',
              to: '/escprofessorturma',
            },
            {
              text: 'Visualizar Config. Professor Turma',
            },
          ],
        },
        props: {
          source: 'escprofessorturma/editar',
          modo: 'visualizar',
        },
      },
    ],
  },

  {
    path: '/escnotasanual',
    name: 'Notas do Aluno',
    redirect: '/escnotasanual/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Notas do Aluno',
        component: loadC('modulos/siges/EscNotasAlunos'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Notas do Aluno',
            },
          ],
        },
        props: {
          source: 'escnotasanual/novo',
          modo: 'novo',
        },
      },
    ],
  },

  {
    path: '/escnotasprofessor',
    name: 'Professor - Lançamento de Notas do Aluno',
    redirect: '/escnotasprofessor/novo',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'novo',
        name: 'Nova/Edita Professor - Lançamento de Notas do Aluno',
        component: loadC('modulos/siges/EscNotasProfessor'),
        meta: {
          breadcrumb: [
            {
              text: 'Nova/Edita Professor - Lançamento de Notas do Aluno',
            },
          ],
        },
        props: {
          source: 'escnotasanual/novo',
          modo: 'novo',
        },
      },
    ],
  },
  {
    path: '/escafastamentos',
    name: 'Afastamentos',
    redirect: '/escafastamentos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Afastamentos',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Afastamentos',
            },
          ],
        },
        props: {
          source: 'escafastamentos/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Afastamento',
        component: loadC('modulos/siges/EscAfastamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Afastamentos',
              to: '/escafastamentos',
            },
            {
              text: 'Novo Afastamento',
            },
          ],
        },
        props: {
          source: 'escafastamentos/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Afastamento',
        component: loadC('modulos/siges/EscAfastamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Afastamentos',
              to: '/escafastamentos',
            },
            {
              text: 'Editar Afastamento',
            },
          ],
        },
        props: {
          source: 'escafastamentos/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Afastamento',
        component: loadC('modulos/siges/EscAfastamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Afastamentos',
              to: '/escafastamentos',
            },
            {
              text: 'Deletar Afastamento',
            },
          ],
        },
        props: {
          source: 'escafastamentos/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Afastamento',
        component: loadC('modulos/siges/EscAfastamentos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Afastamentos',
              to: '/escafastamentos',
            },
            {
              text: 'Visualizar Afastamento',
            },
          ],
        },
        props: {
          source: 'escafastamentos/editar',
          modo: 'visualizar',
        },
      },
    ],
  },
  {
    path: '/escavisoprova',
    name: 'Aviso de Provas',
    redirect: '/escavisoprova/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Aviso de Provas',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Aviso de Provas',
            },
          ],
        },
        props: {
          source: 'escavisoprova/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Aviso de Prova',
        component: loadC('modulos/siges/EscAvisoProva'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Aviso de Provas',
              to: '/escavisoprova',
            },
            {
              text: 'Novo Aviso de Prova',
            },
          ],
        },
        props: {
          source: 'escavisoprova/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Aviso de Prova',
        component: loadC('modulos/siges/EscAvisoProva'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Aviso de Provas',
              to: '/escavisoprova',
            },
            {
              text: 'Editar Aviso de Prova',
            },
          ],
        },
        props: {
          source: 'escavisoprova/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Aviso de Prova',
        component: loadC('modulos/siges/EscAvisoProva'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Aviso de Provas',
              to: '/escavisoprova',
            },
            {
              text: 'Deletar Aviso de Prova',
            },
          ],
        },
        props: {
          source: 'escavisoprova/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Aviso de Prova',
        component: loadC('modulos/siges/EscAvisoProva'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Aviso de Provas',
              to: '/escavisoprova',
            },
            {
              text: 'Visualizar Aviso de Prova',
            },
          ],
        },
        props: {
          source: 'escavisoprova/editar',
          modo: 'visualizar',
        },
      },
    ],
  },
  {
    path: '/escavisos',
    name: 'Avisos',
    redirect: '/escavisos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Avisos',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Avisos',
            },
          ],
        },
        props: {
          source: 'escavisos/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Aviso',
        component: loadC('modulos/siges/EscAvisos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Avisos',
              to: '/escavisos',
            },
            {
              text: 'Novo Aviso',
            },
          ],
        },
        props: {
          source: 'escavisos/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Aviso',
        component: loadC('modulos/siges/EscAvisos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Avisos',
              to: '/escavisos',
            },
            {
              text: 'Editar Aviso',
            },
          ],
        },
        props: {
          source: 'escavisos/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Aviso',
        component: loadC('modulos/siges/EscAvisos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Avisos',
              to: '/escavisos',
            },
            {
              text: 'Deletar Aviso',
            },
          ],
        },
        props: {
          source: 'escavisos/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Aviso',
        component: loadC('modulos/siges/EscAvisos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Avisos',
              to: '/escavisos',
            },
            {
              text: 'Visualizar Aviso',
            },
          ],
        },
        props: {
          source: 'escavisos/editar',
          modo: 'visualizar',
        },
      },
    ],
  },
  {
    path: '/esceventos',
    name: 'Eventos',
    redirect: '/esceventos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'lista',
        name: 'Listagem de Eventos',
        component: load('MctDataViewer'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Eventos',
            },
          ],
        },
        props: {
          source: 'esceventos/lista',
        },
      },
      {
        path: 'novo',
        name: 'Novo Evento',
        component: loadC('modulos/siges/EscEventos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Eventos',
              to: '/esceventos',
            },
            {
              text: 'Novo Evento',
            },
          ],
        },
        props: {
          source: 'esceventos/novo',
          modo: 'novo',
        },
      },
      {
        path: ':codigo/editar',
        name: 'Editar Evento',
        component: loadC('modulos/siges/EscEventos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Eventos',
              to: '/esceventos',
            },
            {
              text: 'Editar Evento',
            },
          ],
        },
        props: {
          source: 'esceventos/editar',
          modo: 'editar',
        },
      },
      {
        path: ':codigo/deletar',
        name: 'Deletar Evento',
        component: loadC('modulos/siges/EscEventos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Eventos',
              to: '/esceventos',
            },
            {
              text: 'Deletar Evento',
            },
          ],
        },
        props: {
          source: 'esceventos/deletar',
          modo: 'deletar',
        },
      },
      {
        path: ':codigo/visualizar',
        name: 'Visualizar Evento',
        component: loadC('modulos/siges/EscEventos'),
        meta: {
          breadcrumb: [
            {
              text: 'Listagem de Eventos',
              to: '/esceventos',
            },
            {
              text: 'Visualizar Evento',
            },
          ],
        },
        props: {
          source: 'esceventos/editar',
          modo: 'visualizar',
        },
      },
    ],
  },
  {
    path: '/escmodelosatestados',
    name: 'Modelo de Atestados',
    redirect: '/escmodelosatestados/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Modelos de Atestados', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Atestados' }] }, props: { source: 'escmodelosatestados/lista' } },
      { path: 'novo', name: 'Novo Modelo de Atestados', component: loadC('modulos/siges/EscModelosAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Atestados', to: '/escmodelosatestados' }, { text: 'Novo Modelo de Atestados' }] }, props: { source: 'escmodelosatestados/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Modelo de Atestados', component: loadC('modulos/siges/EscModelosAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Atestados', to: '/escmodelosatestados' }, { text: 'Editar Modelo de Atestados' }] }, props: { source: 'escmodelosatestados/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Modelo de Atestados', component: loadC('modulos/siges/EscModelosAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Atestados', to: '/escmodelosatestados' }, { text: 'Deletar Modelo de Atestados' }] }, props: { source: 'escmodelosatestados/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Modelo de Atestados', component: loadC('modulos/siges/EscModelosAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Atestados', to: '/escmodelosatestados' }, { text: 'Visualizar Modelo de Atestados' }] }, props: { source: 'escmodelosatestados/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/escatestados',
    name: 'Atestado',
    redirect: '/escatestados/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Atestados', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Atestados' }] }, props: { source: 'escatestados/lista', showButtonCancelar: true } },
      { path: 'novo', name: 'Novo Atestado', component: loadC('modulos/siges/EscAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Atestados', to: '/escatestados' }, { text: 'Novo Atestado' }] }, props: { source: 'escatestados/novo', modo: 'novo' } },
      { path: ':atest_codigo/:atest_ent/:atest_esc/:atest_ano/editar', name: 'Editar Atestado', component: loadC('modulos/siges/EscAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Atestados', to: '/escatestados' }, { text: 'Editar Atestado' }] }, props: { source: 'escatestados/editar', modo: 'editar' } },
      { path: ':atest_codigo/:atest_ent/:atest_esc/:atest_ano/deletar', name: 'Deletar Atestado', component: loadC('modulos/siges/EscAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Atestados', to: '/escatestados' }, { text: 'Deletar Atestado' }] }, props: { source: 'escatestados/deletar', modo: 'deletar' } },
      { path: ':atest_codigo/:atest_ent/:atest_esc/:atest_ano/visualizar', name: 'Visualizar Atestado', component: loadC('modulos/siges/EscAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Atestados', to: '/escatestados' }, { text: 'Visualizar Atestado' }] }, props: { source: 'escatestados/editar', modo: 'editar' } },
      { path: ':atest_codigo/:atest_ent/:atest_esc/:atest_ano/cancelar', name: 'Cancelar Atestado', component: loadC('modulos/siges/EscAtestados'), meta: { breadcrumb: [{ text: 'Listagem de Atestados', to: '/escatestados' }, { text: 'Cancelar Atestado' }] }, props: { source: 'escatestados/cancelar', modo: 'cancelar' } },
    ],
  },
  {
    path: '/escprovas',
    name: 'Prova',
    redirect: '/escprovas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Provas', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Provas' }] }, props: { source: 'escprovas/lista', showButtonCancelar: true } },
      { path: 'novo', name: 'Nova Prova', component: loadC('modulos/siges/EscProvas'), meta: { breadcrumb: [{ text: 'Listagem de Provas', to: '/escprovas' }, { text: 'Nova Prova' }] }, props: { source: 'escprovas/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Prova', component: loadC('modulos/siges/EscProvas'), meta: { breadcrumb: [{ text: 'Listagem de Provas', to: '/escprovas' }, { text: 'Editar Prova' }] }, props: { source: 'escprovas/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Prova', component: loadC('modulos/siges/EscProvas'), meta: { breadcrumb: [{ text: 'Listagem de Provas', to: '/escprovas' }, { text: 'Deletar Prova' }] }, props: { source: 'escprovas/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Prova', component: loadC('modulos/siges/EscProvas'), meta: { breadcrumb: [{ text: 'Listagem de Provas', to: '/escprovas' }, { text: 'Visualizar Prova' }] }, props: { source: 'escprovas/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Prova', component: loadC('modulos/siges/EscProvas'), meta: { breadcrumb: [{ text: 'Listagem de Provas', to: '/escprovas' }, { text: 'Cancelar Prova' }] }, props: { source: 'escprovas/cancelar', modo: 'cancelar' } },
    ],
  },

  {
    path: '/eschescola',
    name: 'Escola (Histórico)',
    redirect: '/eschescola/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Escolas (Histórico)', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Escolas (Histórico)' }] }, props: { source: 'eschescola/lista' } },
      { path: 'novo', name: 'Nova Escola (Histórico)', component: loadC('modulos/siges/EscHistoricoEscolas'), meta: { breadcrumb: [{ text: 'Listagem de Escolas (Histórico)', to: '/eschescola' }, { text: 'Nova Escola (Histórico)' }] }, props: { source: 'eschescola/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Escola (Histórico)', component: loadC('modulos/siges/EscHistoricoEscolas'), meta: { breadcrumb: [{ text: 'Listagem de Escolas (Histórico)', to: '/eschescola' }, { text: 'Editar Escola (Histórico)' }] }, props: { source: 'eschescola/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Escola (Histórico)', component: loadC('modulos/siges/EscHistoricoEscolas'), meta: { breadcrumb: [{ text: 'Listagem de Escolas (Histórico)', to: '/eschescola' }, { text: 'Deletar Escola (Histórico)' }] }, props: { source: 'eschescola/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Escola (Histórico)', component: loadC('modulos/siges/EscHistoricoEscolas'), meta: { breadcrumb: [{ text: 'Listagem de Escolas (Histórico)', to: '/eschescola' }, { text: 'Visualizar Escola (Histórico)' }] }, props: { source: 'eschescola/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Escola (Histórico)', component: loadC('modulos/siges/EscHistoricoEscolas'), meta: { breadcrumb: [{ text: 'Listagem de Escolas (Histórico)', to: '/eschescola' }, { text: 'Cancelar Escola (Histórico)' }] }, props: { source: 'eschescola/cancelar', modo: 'cancelar' } },
    ],
  },
  {
    path: '/eschistoricofund',
    name: 'Histórico Ensino Fundamental',
    redirect: '/eschistoricofund/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Histórico Ensino Fundamental', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Fundamental' }] }, props: { source: 'eschistorico/lista?hist_tipo=F' } },
      { path: 'novo', name: 'Novo Histórico Ensino Fundamental', component: loadC('modulos/siges/EscHistoricoFundamental'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Fundamental', to: '/eschistoricofund' }, { text: 'Novo Histórico Ensino Fundamental' }] }, props: { source: 'eschistorico/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Histórico Ensino Fundamental', component: loadC('modulos/siges/EscHistoricoFundamental'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Fundamental', to: '/eschistoricofund' }, { text: 'Editar Histórico Ensino Fundamental' }] }, props: { source: 'eschistorico/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Histórico Ensino Fundamental', component: loadC('modulos/siges/EscHistoricoFundamental'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Fundamental', to: '/eschistoricofund' }, { text: 'Deletar Histórico Ensino Fundamental' }] }, props: { source: 'eschistorico/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Histórico Ensino Fundamental', component: loadC('modulos/siges/EscHistoricoFundamental'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Fundamental', to: '/eschistoricofund' }, { text: 'Visualizar Histórico Ensino Fundamental' }] }, props: { source: 'eschistorico/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Histórico Ensino Fundamental', component: loadC('modulos/siges/EscHistoricoFundamental'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Fundamental', to: '/eschistoricofund' }, { text: 'Cancelar Histórico Ensino Fundamental' }] }, props: { source: 'eschistorico/cancelar', modo: 'cancelar' } },
    ],
  },
  {
    path: '/eschistoricomed',
    name: 'Histórico Ensino Médio',
    redirect: '/eschistoricomed/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Histórico Ensino Médio', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Médio' }] }, props: { source: 'eschistorico/lista?hist_tipo=M' } },
      { path: 'novo', name: 'Novo Histórico Ensino Médio', component: loadC('modulos/siges/EscHistoricoMedio'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Médio', to: '/eschistoricomed' }, { text: 'Novo Histórico Ensino Médio' }] }, props: { source: 'eschistorico/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Histórico Ensino Médio', component: loadC('modulos/siges/EscHistoricoMedio'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Médio', to: '/eschistoricomed' }, { text: 'Editar Histórico Ensino Médio' }] }, props: { source: 'eschistorico/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Histórico Ensino Médio', component: loadC('modulos/siges/EscHistoricoMedio'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Médio', to: '/eschistoricomed' }, { text: 'Deletar Histórico Ensino Médio' }] }, props: { source: 'eschistorico/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Histórico Ensino Médio', component: loadC('modulos/siges/EscHistoricoMedio'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Médio', to: '/eschistoricomed' }, { text: 'Visualizar Histórico Ensino Médio' }] }, props: { source: 'eschistorico/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Histórico Ensino Médio', component: loadC('modulos/siges/EscHistoricoMedio'), meta: { breadcrumb: [{ text: 'Listagem de Histórico Ensino Médio', to: '/eschistoricomed' }, { text: 'Cancelar Histórico Ensino Médio' }] }, props: { source: 'eschistorico/cancelar', modo: 'cancelar' } },
    ],
  },
  {
    path: '/escplanoanual',
    name: 'Plano Anual',
    redirect: '/escplanoanual/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Plano Anual', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Plano Anual' }] }, props: { source: 'escplanoanual/lista' } },
      { path: 'novo', name: 'Nova Plano Anual', component: loadC('modulos/siges/EscPlanoAnual'), meta: { breadcrumb: [{ text: 'Listagem de Plano Anual', to: '/escplanoanual' }, { text: 'Nova Plano Anual' }] }, props: { source: 'escplanoanual/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Plano Anual', component: loadC('modulos/siges/EscPlanoAnual'), meta: { breadcrumb: [{ text: 'Listagem de Plano Anual', to: '/escplanoanual' }, { text: 'Editar Plano Anual' }] }, props: { source: 'escplanoanual/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Plano Anual', component: loadC('modulos/siges/EscPlanoAnual'), meta: { breadcrumb: [{ text: 'Listagem de Plano Anual', to: '/escplanoanual' }, { text: 'Deletar Plano Anual' }] }, props: { source: 'escplanoanual/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Plano Anual', component: loadC('modulos/siges/EscPlanoAnual'), meta: { breadcrumb: [{ text: 'Listagem de Plano Anual', to: '/escplanoanual' }, { text: 'Visualizar Plano Anual' }] }, props: { source: 'escplanoanual/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Plano Anual', component: loadC('modulos/siges/EscPlanoAnual'), meta: { breadcrumb: [{ text: 'Listagem de Plano Anual', to: '/escplanoanual' }, { text: 'Cancelar Plano Anual' }] }, props: { source: 'escplanoanual/cancelar', modo: 'cancelar' } },
    ],
  },
  {
    path: '/escmodeloscertificados',
    name: 'Modelo de Certificados',
    redirect: '/escmodeloscertificados/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Modelos de Certificados', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Certificados' }] }, props: { source: 'escmodeloscertificados/lista' } },
      { path: 'novo', name: 'Novo Modelo de Certificados', component: loadC('modulos/siges/EscModelosCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Certificados', to: '/escmodeloscertificados' }, { text: 'Novo Modelo de Certificados' }] }, props: { source: 'escmodeloscertificados/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Modelo de Certificados', component: loadC('modulos/siges/EscModelosCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Certificados', to: '/escmodeloscertificados' }, { text: 'Editar Modelo de Certificados' }] }, props: { source: 'escmodeloscertificados/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Modelo de Certificados', component: loadC('modulos/siges/EscModelosCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Certificados', to: '/escmodeloscertificados' }, { text: 'Deletar Modelo de Certificados' }] }, props: { source: 'escmodeloscertificados/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Modelo de Certificados', component: loadC('modulos/siges/EscModelosCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Certificados', to: '/escmodeloscertificados' }, { text: 'Visualizar Modelo de Certificados' }] }, props: { source: 'escmodeloscertificados/editar', modo: 'visualizar' } },
    ],
  },

  {
    path: '/esccertificados',
    name: 'Certificado',
    redirect: '/esccertificados/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Certificados', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Certificados' }] }, props: { source: 'esccertificados/lista' } },
      { path: 'novo', name: 'Nova Certificado', component: loadC('modulos/siges/EscCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Certificados', to: '/esccertificados' }, { text: 'Nova Certificado' }] }, props: { source: 'esccertificados/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Certificado', component: loadC('modulos/siges/EscCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Certificados', to: '/esccertificados' }, { text: 'Editar Certificado' }] }, props: { source: 'esccertificados/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Certificado', component: loadC('modulos/siges/EscCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Certificados', to: '/esccertificados' }, { text: 'Deletar Certificado' }] }, props: { source: 'esccertificados/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Certificado', component: loadC('modulos/siges/EscCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Certificados', to: '/esccertificados' }, { text: 'Visualizar Certificado' }] }, props: { source: 'esccertificados/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Certificado', component: loadC('modulos/siges/EscCertificados'), meta: { breadcrumb: [{ text: 'Listagem de Certificados', to: '/esccertificados' }, { text: 'Cancelar Certificado' }] }, props: { source: 'esccertificados/cancelar', modo: 'cancelar' } },
    ],
  },

  {
    path: '/escprematricula',
    name: 'Pré-Matrícula',
    redirect: '/escprematricula/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Pré-Matrículas', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Pré-Matrículas' }] }, props: { source: 'escprematriculaaluno/lista' } },
      { path: 'novo', name: 'Nova Pré-Matrícula', component: loadC('modulos/siges/EscPreMatricula'), meta: { breadcrumb: [{ text: 'Listagem de Pré-Matrículas', to: '/escprematricula' }, { text: 'Nova Pré-Matrícula' }] }, props: { source: 'escprematriculaaluno/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Pré-Matrícula', component: loadC('modulos/siges/EscPreMatricula'), meta: { breadcrumb: [{ text: 'Listagem de Pré-Matrículas', to: '/escprematricula' }, { text: 'Editar Pré-Matrícula' }] }, props: { source: 'escprematriculaaluno/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Pré-Matrícula', component: loadC('modulos/siges/EscPreMatricula'), meta: { breadcrumb: [{ text: 'Listagem de Pré-Matrículas', to: '/escprematricula' }, { text: 'Deletar Pré-Matrícula' }] }, props: { source: 'escprematriculaaluno/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Pré-Matrícula', component: loadC('modulos/siges/EscPreMatricula'), meta: { breadcrumb: [{ text: 'Listagem de Pré-Matrículas', to: '/escprematricula' }, { text: 'Visualizar Pré-Matrícula' }] }, props: { source: 'escprematriculaaluno/editar', modo: 'editar' } },
      { path: ':codigo/cancelar', name: 'Cancelar Pré-Matrícula', component: loadC('modulos/siges/EscPreMatricula'), meta: { breadcrumb: [{ text: 'Listagem de Pré-Matrículas', to: '/escprematricula' }, { text: 'Cancelar Pré-Matrícula' }] }, props: { source: 'escprematriculaaluno/cancelar', modo: 'cancelar' } },
    ],
  },
  { path: '/escprematriculaconfirma', name: 'Confirmar Pré-Matricula', component: loadC('modulos/siges/EscPreMatriculaConfirma'), meta: { breadcrumb: [{ text: 'Confirmar Pré-Matricula' }] }, props: { source: 'escprematriculaaluno/lista' } },
]
