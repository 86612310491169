function load(component) {
  return () => import(`@/components/comum/${component}`)
}

function loadC(component) {
  return () => import(`@/components/${component}`)
}

export default [
  {
    path: '/dashboards',
    name: 'DashBoards',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboards',
        },
      ],
    },
    component: loadC('dashboards/EscDashBoards'),
  },
  {
    path: '/dashfinanceiro',
    name: 'DashFin',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard Financeira',
        },
      ],
    },
    //component: loadC('dashboards/EscModFinanceiro')
    component: loadC('dashboards/EscDashBoards'),
  },
  {
    path: '/dashvendas',
    name: 'DashVendas',
    //redirect: '/',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard de Vendas',
        },
      ],
    },
    component: loadC('dashboards/EscDashBoards'),
  },
  {
    path: '/dashoutros',
    name: 'DashOutros',
    //redirect: '/',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
        },
      ],
    },
    component: loadC('dashboards/EscDashBoards'),
  },
  {
    path: '/dashadmin',
    name: 'DashAdmin',
    //redirect: '/dashfinanceiro',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard Administrativo',
        },
      ],
    },
    component: loadC('dashboards/EscDashBoards'),
  },
  {
    path: '/dashsecretaria',
    name: 'DashSecretaria',
    //redirect: '/',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard Secretária',
        },
      ],
    },
    //component: loadC('dashboards/EscModSecretaria')
    component: loadC('dashboards/EscDashBoards'),
  },
  {
    path: '/relescfinanceiro',
    name: 'Relatório Financeiro',
    meta: {
      breadcrumb: [
        {
          text: 'Relatório Financeiro',
        },
      ],
    },
    component: loadC('modulos/financeiro/relatorios/RelEscFinanceiro'),
  },
  {
    path: '/retornobanco',
    name: 'Retorno Banco',
    meta: {
      breadcrumb: [
        {
          text: 'Retorno Banco',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscRetornoBanco'),
  },
  {
    path: '/esccentrocusto',
    name: 'Centro de Custo',
    redirect: '/esccentrocusto/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Centro de Custos', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Centro de Custos' }] }, props: { source: 'esccentrocusto/lista' } },
      { path: 'novo', name: 'Novo Centro de Custo', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Centro de Custos', to: '/esccentrocusto' }, { text: 'Novo Centro de Custo' }] }, props: { source: 'esccentrocusto/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Centro de Custo', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Centro de Custos', to: '/esccentrocusto' }, { text: 'Editar Centro de Custo' }] }, props: { source: 'esccentrocusto/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Centro de Custo', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Centro de Custos', to: '/esccentrocusto' }, { text: 'Deletar Centro de Custo' }] }, props: { source: 'esccentrocusto/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Centro de Custo', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Centro de Custos', to: '/esccentrocusto' }, { text: 'Visualizar Centro de Custo' }] }, props: { source: 'esccentrocusto/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esctpquitacao',
    name: 'Tipo de Quitação',
    redirect: '/esctpquitacao/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Tipos de Quitação', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Quitação' }] }, props: { source: 'esctpquitacao/lista' } },
      { path: 'novo', name: 'Novo Tipo de Quitação', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Quitação', to: '/esctpquitacao' }, { text: 'Novo Tipo de Quitação' }] }, props: { source: 'esctpquitacao/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Tipo de Quitação', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Quitação', to: '/esctpquitacao' }, { text: 'Editar Tipo de Quitação' }] }, props: { source: 'esctpquitacao/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Tipo de Quitação', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Quitação', to: '/esctpquitacao' }, { text: 'Deletar Tipo de Quitação' }] }, props: { source: 'esctpquitacao/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Tipo de Quitação', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Quitação', to: '/esctpquitacao' }, { text: 'Visualizar Tipo de Quitação' }] }, props: { source: 'esctpquitacao/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esctprecebimento',
    name: 'Tipo de Recebimento',
    redirect: '/esctprecebimento/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Tipos de Recebimento', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Recebimento' }] }, props: { source: 'esctprecebimento/lista' } },
      { path: 'novo', name: 'Novo Tipo de Recebimento', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Recebimento', to: '/esctprecebimento' }, { text: 'Novo Tipo de Recebimento' }] }, props: { source: 'esctprecebimento/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Tipo de Recebimento', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Recebimento', to: '/esctprecebimento' }, { text: 'Editar Tipo de Recebimento' }] }, props: { source: 'esctprecebimento/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Tipo de Recebimento', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Recebimento', to: '/esctprecebimento' }, { text: 'Deletar Tipo de Recebimento' }] }, props: { source: 'esctprecebimento/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Tipo de Recebimento', component: load('MctFormViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tipos de Recebimento', to: '/esctprecebimento' }, { text: 'Visualizar Tipo de Recebimento' }] }, props: { source: 'esctprecebimento/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esccondpag',
    name: 'Condição de Pagamento',
    redirect: '/esccondpag/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Condições de Pagamento', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Condições de Pagamento' }] }, props: { source: 'esccondpag/lista' } },
      { path: 'novo', name: 'Nova Condição de Pagamento', component: loadC('modulos/financeiro/EscCondPag'), meta: { breadcrumb: [{ text: 'Listagem de Condições de Pagamento', to: '/esccondpag' }, { text: 'Nova Condição de Pagamento' }] }, props: { source: 'esccondpag/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Condição de Pagamento', component: loadC('modulos/financeiro/EscCondPag'), meta: { breadcrumb: [{ text: 'Listagem de Condições de Pagamento', to: '/esccondpag' }, { text: 'Editar Condição de Pagamento' }] }, props: { source: 'esccondpag/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Condição de Pagamento', component: loadC('modulos/financeiro/EscCondPag'), meta: { breadcrumb: [{ text: 'Listagem de Condições de Pagamento', to: '/esccondpag' }, { text: 'Deletar Condição de Pagamento' }] }, props: { source: 'esccondpag/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Condição de Pagamento', component: loadC('modulos/financeiro/EscCondPag'), meta: { breadcrumb: [{ text: 'Listagem de Condições de Pagamento', to: '/esccondpag' }, { text: 'Visualizar Condição de Pagamento' }] }, props: { source: 'esccondpag/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esccontabancaria',
    name: 'Conta Bancária',
    redirect: '/esccontabancaria/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Contas Bancárias', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Contas Bancárias' }] }, props: { source: 'esccontabancaria/lista' } },
      { path: 'novo', name: 'Nova Conta Bancária', component: loadC('modulos/financeiro/EscContaBancaria'), meta: { breadcrumb: [{ text: 'Listagem de Contas Bancárias', to: '/esccontabancaria' }, { text: 'Nova Conta Bancária' }] }, props: { source: 'esccontabancaria/novo', modo: 'novo' } },
      { path: ':cbnc_codigo/:cbnc_ent/editar', name: 'Editar Conta Bancária', component: loadC('modulos/financeiro/EscContaBancaria'), meta: { breadcrumb: [{ text: 'Listagem de Contas Bancárias', to: '/esccontabancaria' }, { text: 'Editar Conta Bancária' }] }, props: { source: 'esccontabancaria/editar', modo: 'editar' } },
      { path: ':cbnc_codigo/:cbnc_ent/deletar', name: 'Deletar Conta Bancária', component: loadC('modulos/financeiro/EscContaBancaria'), meta: { breadcrumb: [{ text: 'Listagem de Contas Bancárias', to: '/esccontabancaria' }, { text: 'Deletar Conta Bancária' }] }, props: { source: 'esccontabancaria/deletar', modo: 'deletar' } },
      { path: ':cbnc_codigo/:cbnc_ent/visualizar', name: 'Visualizar Conta Bancária', component: loadC('modulos/financeiro/EscContaBancaria'), meta: { breadcrumb: [{ text: 'Listagem de Contas Bancárias', to: '/esccontabancaria' }, { text: 'Visualizar Conta Bancária' }] }, props: { source: 'esccontabancaria/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esccaixa',
    name: 'Caixa',
    redirect: '/esccaixa/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Caixas', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Caixas' }] }, props: { source: 'esccaixa/lista' } },
      { path: 'novo', name: 'Novo Caixa', component: loadC('modulos/financeiro/EscCaixa'), meta: { breadcrumb: [{ text: 'Listagem de Caixas', to: '/esccaixa' }, { text: 'Novo Caixa' }] }, props: { source: 'esccaixa/novo', modo: 'novo' } },
      { path: ':cxa_codigo/:cxa_ent/editar', name: 'Editar Caixa', component: loadC('modulos/financeiro/EscCaixa'), meta: { breadcrumb: [{ text: 'Listagem de Caixas', to: '/esccaixa' }, { text: 'Editar Caixa' }] }, props: { source: 'esccaixa/editar', modo: 'editar' } },
      { path: ':cxa_codigo/:cxa_ent/deletar', name: 'Deletar Caixa', component: loadC('modulos/financeiro/EscCaixa'), meta: { breadcrumb: [{ text: 'Listagem de Caixas', to: '/esccaixa' }, { text: 'Deletar Caixa' }] }, props: { source: 'esccaixa/deletar', modo: 'deletar' } },
      { path: ':cxa_codigo/:cxa_ent/visualizar', name: 'Visualizar Caixa', component: loadC('modulos/financeiro/EscCaixa'), meta: { breadcrumb: [{ text: 'Listagem de Caixas', to: '/esccaixa' }, { text: 'Visualizar Caixa' }] }, props: { source: 'esccaixa/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esccursostabela',
    name: 'Tabela de Valores Curso',
    redirect: '/esccursostabela/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Tabela de Valores Cursos', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Tabela de Valores Cursos' }] }, props: { source: 'esccursostabela/lista' } },
      { path: 'novo', name: 'Nova Tabela de Valores Curso', component: loadC('modulos/financeiro/EscCursosTabela'), meta: { breadcrumb: [{ text: 'Listagem de Tabela de Valores Cursos', to: '/esccursostabela' }, { text: 'Nova Tabela de Valores Curso' }] }, props: { source: 'esccursostabela/novo', modo: 'novo' } },
      { path: ':ctab_codigo/:ctab_ent/:ctab_esc/:ctab_ano/editar', name: 'Editar Tabela de Valores Curso', component: loadC('modulos/financeiro/EscCursosTabela'), meta: { breadcrumb: [{ text: 'Listagem de Tabela de Valores Cursos', to: '/esccursostabela' }, { text: 'Editar Tabela de Valores Curso' }] }, props: { source: 'esccursostabela/editar', modo: 'editar' } },
      { path: ':ctab_codigo/:ctab_ent/:ctab_esc/:ctab_ano/deletar', name: 'Deletar Tabela de Valores Curso', component: loadC('modulos/financeiro/EscCursosTabela'), meta: { breadcrumb: [{ text: 'Listagem de Tabela de Valores Cursos', to: '/esccursostabela' }, { text: 'Deletar Tabela de Valores Curso' }] }, props: { source: 'esccursostabela/deletar', modo: 'deletar' } },
      { path: ':ctab_codigo/:ctab_ent/:ctab_esc/:ctab_ano/visualizar', name: 'Visualizar Tabela de Valores Curso', component: loadC('modulos/financeiro/EscCursosTabela'), meta: { breadcrumb: [{ text: 'Listagem de Tabela de Valores Cursos', to: '/esccursostabela' }, { text: 'Visualizar Tabela de Valores Curso' }] }, props: { source: 'esccursostabela/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/escconvenios',
    name: 'Convênio',
    redirect: '/escconvenios/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Convênios', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Convênios' }] }, props: { source: 'escconvenios/lista' } },
      { path: 'novo', name: 'Novo Convênio', component: loadC('modulos/financeiro/EscConvenios'), meta: { breadcrumb: [{ text: 'Listagem de Convênios', to: '/escconvenios' }, { text: 'Novo Convênio' }] }, props: { source: 'escconvenios/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Convênio', component: loadC('modulos/financeiro/EscConvenios'), meta: { breadcrumb: [{ text: 'Listagem de Convênios', to: '/escconvenios' }, { text: 'Editar Convênio' }] }, props: { source: 'escconvenios/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Convênio', component: loadC('modulos/financeiro/EscConvenios'), meta: { breadcrumb: [{ text: 'Listagem de Convênios', to: '/escconvenios' }, { text: 'Deletar Convênio' }] }, props: { source: 'escconvenios/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Convênio', component: loadC('modulos/financeiro/EscConvenios'), meta: { breadcrumb: [{ text: 'Listagem de Convênios', to: '/escconvenios' }, { text: 'Visualizar Convênio' }] }, props: { source: 'escconvenios/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esccontratos',
    name: 'Contrato',
    redirect: '/esccontratos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Contratos', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Contratos' }] }, props: { source: 'esccontratos/lista', showButtonCancelar: true } },
      { path: 'novo', name: 'Novo Contrato', component: loadC('modulos/financeiro/EscContratos'), meta: { breadcrumb: [{ text: 'Listagem de Contratos', to: '/esccontratos' }, { text: 'Novo Contrato' }] }, props: { source: 'esccontratos/novo', modo: 'novo' } },
      { path: ':cntr_codigo/:cntr_ent/:cntr_esc/:cntr_ano/editar', name: 'Editar Contrato', component: loadC('modulos/financeiro/EscContratos'), meta: { breadcrumb: [{ text: 'Listagem de Contratos', to: '/esccontratos' }, { text: 'Editar Contrato' }] }, props: { source: 'esccontratos/editar', modo: 'editar' } },
      { path: ':cntr_codigo/:cntr_ent/:cntr_esc/:cntr_ano/deletar', name: 'Deletar Contrato', component: loadC('modulos/financeiro/EscContratos'), meta: { breadcrumb: [{ text: 'Listagem de Contratos', to: '/esccontratos' }, { text: 'Deletar Contrato' }] }, props: { source: 'esccontratos/deletar', modo: 'deletar' } },
      { path: ':cntr_codigo/:cntr_ent/:cntr_esc/:cntr_ano/visualizar', name: 'Visualizar Contrato', component: loadC('modulos/financeiro/EscContratos'), meta: { breadcrumb: [{ text: 'Listagem de Contratos', to: '/esccontratos' }, { text: 'Visualizar Contrato' }] }, props: { source: 'esccontratos/editar', modo: 'editar' } },
      { path: ':cntr_codigo/:cntr_ent/:cntr_esc/:cntr_ano/cancelar', name: 'Cancelar Contrato', component: loadC('modulos/financeiro/EscContratos'), meta: { breadcrumb: [{ text: 'Listagem de Contratos', to: '/esccontratos' }, { text: 'Cancelar Contrato' }] }, props: { source: 'esccontratos/cancelar', modo: 'cancelar' } },
    ],
  },
  {
    path: '/escreceber',
    name: 'Contas a Receber',
    redirect: '/escreceber/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Contas a Receber', component: loadC('modulos/financeiro/EscReceberLista'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Receber' }] }, props: { source: 'escreceber/lista' } },
      { path: 'novo', name: 'Novo Contas a Receber', component: loadC('modulos/financeiro/EscReceber'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Receber', to: '/escreceber' }, { text: 'Novo Contas a Receber' }] }, props: { source: 'escreceber/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Contas a Receber', component: loadC('modulos/financeiro/EscReceber'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Receber', to: '/escreceber' }, { text: 'Editar Contas a Receber' }] }, props: { source: 'escreceber/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Contas a Receber', component: loadC('modulos/financeiro/EscReceber'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Receber', to: '/escreceber' }, { text: 'Deletar Contas a Receber' }] }, props: { source: 'escreceber/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Contas a Receber', component: loadC('modulos/financeiro/EscReceber'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Receber', to: '/escreceber' }, { text: 'Visualizar Contas a Receber' }] }, props: { source: 'escreceber/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/escpagar',
    name: 'Contas a Pagar',
    redirect: '/escpagar/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Contas a Pagar', component: loadC('modulos/financeiro/EscPagarLista'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Pagar' }] }, props: { source: 'escpagar/lista' } },
      { path: 'novo', name: 'Novo Contas a Pagar', component: loadC('modulos/financeiro/EscPagar'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Pagar', to: '/escpagar' }, { text: 'Novo Contas a Pagar' }] }, props: { source: 'escpagar/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Contas a Pagar', component: loadC('modulos/financeiro/EscPagar'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Pagar', to: '/escpagar' }, { text: 'Editar Contas a Pagar' }] }, props: { source: 'escpagar/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Contas a Pagar', component: loadC('modulos/financeiro/EscPagar'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Pagar', to: '/escpagar' }, { text: 'Deletar Contas a Pagar' }] }, props: { source: 'escpagar/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Contas a Pagar', component: loadC('modulos/financeiro/EscPagar'), meta: { breadcrumb: [{ text: 'Listagem de Contas a Pagar', to: '/escpagar' }, { text: 'Visualizar Contas a Pagar' }] }, props: { source: 'escpagar/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/esctransferencias',
    name: 'Transferência',
    redirect: '/esctransferencias/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Transferências', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Transferências' }] }, props: { source: 'esctransferencias/lista' } },
      { path: 'novo', name: 'Nova Transferência', component: loadC('modulos/financeiro/EscTransferencias'), meta: { breadcrumb: [{ text: 'Listagem de Transferências', to: '/esctransferencias' }, { text: 'Nova Transferência' }] }, props: { source: 'esctransferencias/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Transferência', component: loadC('modulos/financeiro/EscTransferencias'), meta: { breadcrumb: [{ text: 'Listagem de Transferências', to: '/esctransferencias' }, { text: 'Editar Transferência' }] }, props: { source: 'esctransferencias/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Transferência', component: loadC('modulos/financeiro/EscTransferencias'), meta: { breadcrumb: [{ text: 'Listagem de Transferências', to: '/esctransferencias' }, { text: 'Deletar Transferência' }] }, props: { source: 'esctransferencias/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Transferência', component: loadC('modulos/financeiro/EscTransferencias'), meta: { breadcrumb: [{ text: 'Listagem de Transferências', to: '/esctransferencias' }, { text: 'Visualizar Transferência' }] }, props: { source: 'esctransferencias/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/escmodeloscontratos',
    name: 'Modelo de Contrato',
    redirect: '/escmodeloscontratos/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Modelos de Contrato', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Contrato' }] }, props: { source: 'escmodeloscontratos/lista' } },
      { path: 'novo', name: 'Novo Modelo de Contrato', component: loadC('modulos/financeiro/EscModelosContratos'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Contrato', to: '/escmodeloscontratos' }, { text: 'Novo Modelo de Contrato' }] }, props: { source: 'escmodeloscontratos/novo', modo: 'novo' } },
      { path: ':codigo/editar', name: 'Editar Modelo de Contrato', component: loadC('modulos/financeiro/EscModelosContratos'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Contrato', to: '/escmodeloscontratos' }, { text: 'Editar Modelo de Contrato' }] }, props: { source: 'escmodeloscontratos/editar', modo: 'editar' } },
      { path: ':codigo/deletar', name: 'Deletar Modelo de Contrato', component: loadC('modulos/financeiro/EscModelosContratos'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Contrato', to: '/escmodeloscontratos' }, { text: 'Deletar Modelo de Contrato' }] }, props: { source: 'escmodeloscontratos/deletar', modo: 'deletar' } },
      { path: ':codigo/visualizar', name: 'Visualizar Modelo de Contrato', component: loadC('modulos/financeiro/EscModelosContratos'), meta: { breadcrumb: [{ text: 'Listagem de Modelos de Contrato', to: '/escmodeloscontratos' }, { text: 'Visualizar Modelo de Contrato' }] }, props: { source: 'escmodeloscontratos/editar', modo: 'visualizar' } },
    ],
  },
  {
    path: '/escfinanceiro',
    name: 'Listagem Financeiro',
    meta: {
      breadcrumb: [
        {
          text: 'Listagem Financeiro',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscFinanceiroLista'),
    props: { source: 'escfinanceiro/lista' },
  },
  {
    path: '/imprimirboletos',
    name: 'Imprimir Boletos',
    meta: {
      breadcrumb: [
        {
          text: 'Imprimir Boletos',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscBoletosLista'),
    props: { source: 'boletos/lista' },
  },
  {
    path: '/escquitacao',
    name: 'Quitação Múltipla',
    meta: {
      breadcrumb: [
        {
          text: 'Quitação Múltipla',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscQuitacao'),
    props: { modo: 'novo' },
  },
  {
    path: '/esclancfinanceiro',
    name: 'Lançamento Financeiro',
    meta: {
      breadcrumb: [
        {
          text: 'Lançamento Financeiro',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscLancamentos'),
    props: { modo: 'novo' },
  },
  {
    path: '/escextrato',
    name: 'Extrato',
    meta: {
      breadcrumb: [
        {
          text: 'Extrato',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscExtratos'),
    props: { modo: 'novo' },
  },
  {
    path: '/escextrato/banco',
    name: 'Extrato Banco',
    meta: {
      breadcrumb: [
        {
          text: 'Extrato Banco',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscExtratoBanco'),
    props: { source: 'escextratos/banco' },
  },
  {
    path: '/escextrato/caixa',
    name: 'Extrato Caixa',
    meta: {
      breadcrumb: [
        {
          text: 'Extrato Caixa',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscExtratoCaixa'),
    props: { source: 'escextratos/caixa' },
  },
  {
    path: '/escconciliacao',
    name: 'Conciliação Bancária',
    meta: {
      breadcrumb: [
        {
          text: 'Conciliação Bancária',
        },
      ],
    },
    component: loadC('modulos/financeiro/EscConciliacao'),
    props: { source: 'escconciliacao/conciliacao' },
  },
  { path: '/escemitirnfse', name: 'Emitir Nota Fiscal de Serviços (NFse)', component: loadC('modulos/financeiro/EscEmissaoNFse'), meta: { breadcrumb: [{ text: 'Emitir Nota Fiscal de Serviços (NFse)' }] }, props: { source: 'escemitirnfse/lista' } },

  {
    path: '/esctaxas',
    name: 'Taxas',
    redirect: '/esctaxas/lista',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      { path: 'lista', name: 'Listagem de Taxas', component: load('MctDataViewer'), meta: { breadcrumb: [{ text: 'Listagem de Taxas' }] }, props: { source: 'esctaxas/lista' } },
      { path: 'novo', name: 'Novo Taxas', component: loadC('modulos/financeiro/EscTaxas'), meta: { breadcrumb: [{ text: 'Listagem de Taxas', to: '/esctaxas' }, { text: 'Novo Taxas' }] }, props: { source: 'esctaxas/novo', modo: 'novo' } },
      { path: ':tx_codigo/:tx_ent/:tx_esc/:tx_ano/editar', name: 'Editar Taxas', component: loadC('modulos/financeiro/EscTaxas'), meta: { breadcrumb: [{ text: 'Listagem de Taxas', to: '/esctaxas' }, { text: 'Editar Taxas' }] }, props: { source: 'esctaxas/editar', modo: 'editar' } },
      { path: ':tx_codigo/:tx_ent/:tx_esc/:tx_ano/deletar', name: 'Deletar Taxas', component: loadC('modulos/financeiro/EscTaxas'), meta: { breadcrumb: [{ text: 'Listagem de Taxas', to: '/esctaxas' }, { text: 'Deletar Taxas' }] }, props: { source: 'esctaxas/deletar', modo: 'deletar' } },
      { path: ':tx_codigo/:tx_ent/:tx_esc/:tx_ano/visualizar', name: 'Visualizar Taxas', component: loadC('modulos/financeiro/EscTaxas'), meta: { breadcrumb: [{ text: 'Listagem de Taxas', to: '/esctaxas' }, { text: 'Visualizar Taxas' }] }, props: { source: 'esctaxas/editar', modo: 'visualizar' } },
    ],
  },
]
